import http from '../axios/http'

// 请求模块
const apiModel = '/article'

// 编写笔记
export function writeNotes(params) {
  return http.post(apiModel + '/notes/write', params)
}

// 查询内容
export function notesContent(params) {
  return http.get(apiModel + '/notes/content', params)
}

// 笔记分页
export function notesPage(params) {
  return http.get(apiModel + '/notes/page', params)
}

// 笔记删除
export function deleteNotes(params) {
  return http.delete(apiModel + '/notes/delete', params)
}

// 笔记信息
export function infoNotes(params) {
  return http.get(apiModel + '/notes/info', params)
}

// 更新笔记信息
export function updateInfoNotes(params) {
  return http.put(apiModel + '/notes/update/info', params)
}

// 更新笔记内容
export function updateContentNotes(params) {
  return http.put(apiModel + '/notes/update/content', params)
}

// 已发布文章分页
export function notesPushPage(params) {
  return http.get(apiModel + '/notes/push/page', params)
}

// 已发布文章内容详情
export function notesPushContentDetail(params) {
  return http.get(apiModel + '/notes/push/content/detail', params)
}

// 目录树
export function catalogTree() {
  return http.get(apiModel + '/catalog/tree')
}

// 目录文件树
export function catalogTreeFile() {
  return http.get(apiModel + '/catalog/tree/file')
}

// 创建目录
export function createCatalog(params) {
  return http.post(apiModel + '/catalog/create', params)
}

// 删除目录
export function deleteCatalog(params) {
  return http.delete(apiModel + '/catalog/delete', params)
}

// 更新目录名称
export function updateCatalogTitle(params) {
  return http.put(apiModel + '/catalog/update/title', params)
}

// 移动目录
export function moveCatalog(params) {
  return http.post(apiModel + '/catalog/move', params)
}

// 保存草稿
export function pageManuscript(params) {
  return http.get(apiModel + '/manuscript/page', params)
}

// 保存草稿
export function saveManuscript(params) {
  return http.post(apiModel + '/manuscript/add', params)
}

// 修改草稿
export function updateManuscript(params) {
  return http.put(apiModel + '/manuscript/update', params)
}

// 草稿详情
export function detailManuscript(params) {
  return http.get(apiModel + '/manuscript/detail', params)
}

// 删除草稿
export function deleteManuscript(params) {
  return http.delete(apiModel + '/manuscript/delete', params)
}

// 标签列表
export function tags(params) {
  return http.get(apiModel + '/tag/list', params)
}

// 添加标签
export function createTag(params) {
  return http.post(apiModel + '/tag/create', params)
}

// 删除标签
export function deleteTag(params) {
  return http.delete(apiModel + '/tag/delete', params)
}
