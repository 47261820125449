<template>
  <div class="hall-detail">
    <div class="detail-background">
      <el-image
        :src="
          cover === null || cover.length === 0
            ? require('@/assets/default/default_primary.webp')
            : cover
        "
        fit="cover"
      ></el-image>
      <div class="detail-head">
        <h2>{{ title }}</h2>
        <div class="detail-tags">
          <span v-for="(tag, index) in tags" :key="index" :serial="tag.serial">
            {{ tag.tagName }}
          </span>
        </div>
        <div class="detail-num">
          <span>发布时间 {{ pushTime }}</span>
          ·
          <span>{{ readNum }} 次阅读</span>
        </div>
      </div>
    </div>
    <div class="detail-content">
      <app-preview :content="content" ref="preview" class="detail-preview" />
      <el-affix :offset="50">
        <div class="preview-title" v-if="contentCatalog.length > 0">
          <el-scrollbar ref="contentCatalog">
            <div
              v-for="(anchor, index) in contentCatalog"
              :key="index"
              @click="handleAnchorClick(anchor)"
              class="title-style"
              :style="{ padding: `12px 0 0 ${(anchor.indent + 1) * 20}px` }"
            >
              <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                {{ anchor.title }}
              </p>
            </div>
          </el-scrollbar>
        </div>
      </el-affix>
    </div>
  </div>
</template>

<script>
import MarkdownPreview from '@/components/editor/MarkdownPreview'
import { notesPushContentDetail } from '@/api/ArticleApi'

export default {
  name: 'HallDetail',
  components: {
    'app-preview': MarkdownPreview
  },
  data() {
    return {
      title: '',
      cover: '',
      readNum: 0,
      tags: [],
      pushTime: '',
      content: '',
      contentCatalog: []
    }
  },
  methods: {
    queryDetail(serial) {
      notesPushContentDetail({
        serial: serial
      }).then((res) => {
        if (res.code === 200) {
          document.title = res.data.title + ' - 凤凰集'
          this.tags = res.data.tags
          this.cover = res.data.cover
          this.title = res.data.title
          this.content = res.data.content
          this.readNum = res.data.readNum
          this.pushTime = res.data.pushTime
          this.contentCatalog = res.data.contentCatalog
        } else {
          this.$message({ message: res.msg, type: 'error' })
          this.$router.replace('/')
        }
      })
    },
    handleAnchorClick(anchor) {
      this.$refs.preview.currentPosition(anchor)
    },
    scrollProgress() {
      this.$nextTick(() => {
        const contentCatalog = this.$refs.contentCatalog
        if (contentCatalog) {
          this.$refs.preview.createCatalog(contentCatalog)
        }
      })
    }
  },
  mounted() {
    const serial = this.$route.query.serial
    if (typeof serial === 'undefined' || serial === '') {
      this.$router.replace('/')
      return
    }
    this.queryDetail(serial)
    window.addEventListener('scroll', this.scrollProgress)
  },
  beforeUnmount() {
    // 页面销毁前
    window.removeEventListener('scroll', this.scrollProgress)
  }
}
</script>

<style scoped>
.hall-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detail-background {
  width: 100%;
  height: 500px;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
}

.detail-background >>> .el-image {
  width: 100%;
  height: 100%;
}

.detail-head {
  width: 70%;
  height: 100px;
  position: absolute;
  top: 300px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.detail-head h2 {
  font-size: 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.detail-tags {
  margin: 10px 0;
  text-align: right;
}

.detail-tags span {
  margin: 0 10px;
}

.detail-num {
  text-align: right;
}

.detail-num span {
  margin: 0 10px;
}

.detail-content {
  width: 80%;
  margin: 50px 0;
  display: flex;
  justify-content: space-around;
}

.detail-content .detail-preview {
  width: 75%;
}

.detail-content .el-affix {
  width: 20%;
}

.detail-content .preview-title {
  padding: 10px;
  width: 100%;
  height: 500px;
  background-color: #ffffff;
}
</style>
