<template>
  <div class="preview-markdown">
    <v-md-preview :text="content" ref="preview"></v-md-preview>
  </div>
</template>

<script>
export default {
  name: 'MarkdownPreview',
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  methods: {
    createCatalog(contentCatalog) {
      const { preview } = this.$refs
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const hs = preview.$el.querySelectorAll('h1,h2,h3,h4,h5,h6')
      const ch = Array.from(hs).find((h) => h.offsetTop >= scrollTop)
      if (contentCatalog) {
        const ps = contentCatalog.$el.querySelectorAll('p')
        Array.from(ps).forEach(function (p) {
          if (typeof p !== 'undefined' && typeof ch !== 'undefined') {
            p.className = p.outerText === ch.outerText ? 'title-style-select' : 'title-style'
          }
        })
      }
    },
    currentPosition(anchor) {
      const { preview } = this.$refs
      const { lineIndex } = anchor
      const heading = preview.$el.querySelector(`[data-v-md-line="${lineIndex}"]`)
      if (heading) {
        preview.scrollToTarget({
          target: heading,
          scrollContainer: window,
          top: 80
        })
      }
    }
  }
}
</script>

<style scoped>
/*允许选中复制*/
.preview-markdown >>> * {
  -webkit-user-select: text;
  -ms-user-select: text;
  -moz-user-select: text;
  user-select: text;
}

/*todo样式*/
.preview-markdown >>> .vuepress-markdown-body > ul.v-md-editor__todo-list {
  padding-left: 2.4em;
}

/*todo样式*/
.preview-markdown >>> .vuepress-markdown-body > ul.v-md-editor__todo-list li {
  list-style: none;
}

/*无序列表样式*/
.preview-markdown >>> .vuepress-markdown-body ul li {
  list-style: disc;
}

/*有序列表样式*/
.preview-markdown >>> .vuepress-markdown-body ol li {
  list-style: decimal;
}

/*h2下划线*/
.preview-markdown >>> .vuepress-markdown-body h2 {
  border-bottom: 0;
}
</style>
